import moment from 'moment';

export function getCurrentDate() {
    return moment.now();
}

export function formatDate(date) {
    return moment(date).format('MMM-Do-YY');
}

export function formatDateAndTime(date) {
    return moment(date).format(" MMMM Do YYYY, hA");
}

export function formatDateTime(date){
    return moment(date).format();
}
export function getDuration(date) {
    return moment(date).fromNow();
}

export function getFullDateFromDay(day) {
    return moment().dayOfYear(day).format("MMM-Do-YY");
}