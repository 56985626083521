import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {ApiProvider, PageProvider} from './context';
import {QueryCache, ReactQueryCacheProvider} from 'react-query';
import { ReactQueryDevtools } from 'react-query-devtools';
import ErrorBoundary from './errorBoundary/ErrorBoundary';


const queryCache = new QueryCache();

ReactDOM.render(
  <React.StrictMode>
      <ApiProvider>
        <PageProvider>
    <ReactQueryCacheProvider queryCache={queryCache}>
  {/* <ErrorBoundary> */}
    <App />
    {/* </ErrorBoundary> */}
    </ReactQueryCacheProvider>
    </PageProvider>
    </ApiProvider>
    {/* <ReactQueryDevtools initialIsOpen /> */}
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
