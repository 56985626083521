import * as localStore from '../helpers/localStorageHelper'

import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL ;
// const apiUrl = 'http://91.10'
const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    // "Content-Type": "multipart/form-data",
    'auth-token': `${localStore.getToken()}`
  }

const instance = axios.create({
    baseURL: apiUrl + "admin/",
    timeout: 500000,
    headers
  });

  instance.interceptors.response.use(function (response) {
      response = {...response, status: 200, statusCode: 200};
      return response;
  }, function (error) {
    console.log(error.response.status);
    if(error.response.status === 401){
      return redirectToLogin();
    }
      console.log(error);
  });
function redirectToLogin() {
  let publicUrl = process.env.PUBLIC_URL;
        let loginPageUrl = null;
      
          loginPageUrl = publicUrl + '/login';
        
        
        let origin = window.location.origin;
        if (window.location.origin === origin + loginPageUrl) return;
        window.location.href = loginPageUrl;
}


function processResult (response) {
    let {statusCode} = response;
    console.log(response);
    if(statusCode === 200) {
      return {success: response.data.status, data: response.data.data, message: response.data.message};
    } else if(statusCode === 500){
      return {success: false, message: 'Cannot connect to the internet'}
    } 
      return {success: response.data.status, message: response.data.message};    
}


function postLogin(loginData) {


    console.log(process.env.REACT_APP_API_URL);

    return axios.post(apiUrl+'auth/login', loginData)
      .then(res=> {
          let {data} = res.data;
        //   console.log(results);
          localStore.setToken(data['auth-token']);
          localStore.setUserId(data.user._id);
        //   localStore.setExpiresAt(data.expiresin);
          localStore.setName(data.user.name);
        //   localStore.setRole(data.info.roles[0].id);
          return {statusCode: 200, data: 'Success'}
      }).catch(error => {
          if(error.response) {
            if (error.response.status === 401){
              return {statusCode: 401, data: "You are not an admin"};
            }
             if( error.response.status == 422) {
                 return {statusCode: 422, data: "Email and password does not match any record"}
             }
             else {
              return {statusCode: 500, data: "Error in internet connection"} 
             }
          }else {
              console.log('an error ', error)
              return {statusCode: 500, data: "Error in internet connection"}
      }
  
    });
    }


    // USER MGT
    async function createUser (data) {
      let results = await instance.post('users/create', data);
      return processResult(results);
    }

    async function topUpWallet (data) {
      let results = await instance.post('wallet/topUp', data);
      return processResult(results);
    }

    async function changeCommissionRate (data) {
      let results = await instance.post('wallet/commissionRate', data);
      return processResult(results);
    }

    async function changeNetworkCommissionRate (data) {
      let results = await instance.post('networks/updateCommission', data);
      return processResult(results);
    }

    async function deactivateUser (data) {
      let results = await instance.post('users/deactivate', data);
      return processResult(results);
    }

    async function activateUser (data) {
      let results = await instance.post('users/activate', data);
      return processResult(results);
    }

    async function editBundle (data, id) {
      let results = await instance.patch(`bundles/${id}`, data);
      return processResult(results);
    }

    async function deductWallet (data, id) {
      let results = await instance.post(`wallet/commissionDeduct`, data);
      return processResult(results);
    }

  export {instance, postLogin, createUser, topUpWallet, 
    changeCommissionRate, changeNetworkCommissionRate,
  deactivateUser, activateUser, editBundle, deductWallet}