import React from 'react';
import {Link} from 'react-router-dom';

export default class ErrorBoundary extends React.Component {
    constructor(props){
        super(props);
        this.state = {error: null, errorInfo: false}
    }

    componentDidCatch(error, errorInfo) {
        this.setState({
            error: error,
            errorInfo: errorInfo
        })
    }

    render() {
        if(this.state.errorInfo) {
            return (
                <div>
          <h2>Something went wrong.</h2>
          <Link to="/dashboard" > Return to dashboard </Link> &nbsp; &nbsp; 
          <details style={{ whiteSpace: 'pre-wrap' }}>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo.componentStack}
            <br />
            
            
          </details>
        </div>
            )
        }

        return this.props.children
    }
}