import React, { Suspense, lazy, useContext } from "react";
import './App.css';
import {getToken} from './helpers';
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import ErrorBoundary from './errorBoundary/ErrorBoundary';
 
const Login = lazy(() => import("./pages/auth/Login"));
const Dashboard = lazy(() => import("./pages/dashboard/Dashboard"));

//================ USERS ==========
const ViewUsers = lazy(() => import("./pages/users/ViewUsers"));
const CreateUser = lazy(() => import("./pages/users/CreateUser"));
const UserDetail = lazy(() => import("./pages/users/UserDetail"));

//================ CONTACTS ==========
const ViewContacts = lazy(() => import("./pages/contacts/ViewContacts"));

//================ BUNDLES ==========
const ViewBundles = lazy(() => import("./pages/dataBundles/ViewBundles"));
const EditBundle = lazy(() => import("./pages/dataBundles/EditBundle"));

//================ TRANSACTIONS ==========
const ViewDebitTransactions = lazy(()=> import("./pages/transactions/ViewDebitTransactions"));
const ViewCreditTransactions = lazy(() => import("./pages/transactions/ViewCreditTransactions"));
const TransactionDetails = lazy(() => import("./pages/transactions/TransactionDetail"));
const GloTransactions = lazy(()=> import("./pages/reports/GloDailyTransaction"));

// ============ NETWORKS ==============
const CommissionRates = lazy(() => import("./pages/networks/CommissionRates"));

//=============== REPORTS =============
const DailyLogs = lazy(() => import("./pages/reports/DailyLog"));
const DownloadReports = lazy(() => import("./pages/reports/DownloadReports"));
const SuccessReportByRange = lazy(() => import("./pages/reports/SuccessReportByRange"));
const DailyPartnerReport = lazy(() => import("./pages/reports/DailyPartnerReport"));

//=============== REPORTS =============
const WalletDeduction = lazy(() => import("./pages/wallet/WalletDeduction"));
const PartnerDailyBalance = lazy(() => import("./pages/reports/PartnerDailyBalance"));

function App() {
  const baseUrl = process.env.PUBLIC_URL;

  return (
    <BrowserRouter basename={baseUrl}>
     <Suspense fallback={<div>Loading...</div>}>
     <Switch>
     <ErrorBoundary>
       <Route path="/" exact component={Login} />
       <Route path="/login" exact component={Login} />
       <Route path="/dashboard" component={Dashboard} />
       <Route path="/users" exact component={ViewUsers} />
       <Route path="/users/create" component={CreateUser} />
       <Route path="/users/:id" component={UserDetail} />

       <Route path="/contacts" component={ViewContacts} />

        <Route path="/networks/:name" component={ViewBundles}/>

        <Route path="/bundles/:bundleId" component={EditBundle} />
        
        <Route path="/transactions/debit" component={ViewDebitTransactions} />

        <Route path="/transactions/credit" component={ViewCreditTransactions} />

        <Route path="/transactions/details" component={TransactionDetails} />

        <Route path="/commissionRates" component={CommissionRates} />

        <Route path="/glo-transactions" component={GloTransactions} />
        
        <Route path="/dailyLogs/:network" component={DailyLogs} />

        <Route path="/daily-reports" component={DownloadReports} />

        <Route path="/success-reports" component={SuccessReportByRange} />

        <Route path="/wallet-deduction" component={WalletDeduction} />

        <Route path="/daily-partner-report" component={DailyPartnerReport} />

        <Route path="/daily-partner-balance" component={PartnerDailyBalance} />
        </ErrorBoundary>
       </Switch>
       </Suspense>
    </BrowserRouter>
  );
}

export default App;

const Authenticated = ({childredn}) => {
    let token = getToken();

}